import { ActivityLogEntry, Actor, Incident, Stream } from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Avatar,
  Badge,
  BadgeTheme,
  Button,
  ButtonSize,
  ButtonTheme,
  Icon,
  IconBadge,
  IconEnum,
  IconSize,
  LocalDateTime,
  Tooltip,
} from "@incident-ui";
import * as Sentry from "@sentry/react";
import React from "react";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { ActivityItemTypeToComponentMap } from "./activity-items/ActivityItem";
import {
  ActivityItemError,
  activityItemGenericErrorContent,
  activityItemGenericErrorTitle,
  getEnrichmentError,
} from "./ActivityItemError";

export enum ActivityLogItemMode {
  // Associated to a timeline item on the incident details page.
  Timeline,
  // Associated to a timeline item, in the editor.
  EditTimelineItem,
  // In the activity log drawer, accessed from the incident details page.
  ActivityLogDrawer,
  // In the activity log, in the timeline editor.
  ActivityLogInEditMode,
}

type ActivityLogItemUIProps = {
  incident: Incident;
  mode: ActivityLogItemMode;
  item: ActivityLogEntry;
  zoomImageSource: string | undefined;
  className?: string;
  // isDisabledOption is true if this activity item is a disabled option in the timeline editor.
  isDisabledOption?: boolean;
  setZoomImageSource: (value: React.SetStateAction<string | undefined>) => void;
  onShow?: (props: { incidentId: string; itemId: string }) => void;
};

export const ActivityLogItemUIWrapper = (
  props: ActivityLogItemUIProps,
): React.ReactElement | null => {
  // If the backend returned an enrichment error for this item (e.g. we couldn't enrich a slack message),
  // show an error message which explains what went wrong.
  const enrichmentErrorResult = getEnrichmentError(props.item);

  if (enrichmentErrorResult) {
    return (
      <ActivityItemError
        title={enrichmentErrorResult.title}
        content={enrichmentErrorResult.content}
        item={props.item}
        onShow={props.onShow}
      />
    );
  }

  return (
    // We also wrap the activity item in a Sentry.ErrorBoundary so that we can catch any unexpected
    // front-end errors and show a generic error message.
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => (
        <ActivityItemError
          title={activityItemGenericErrorTitle}
          content={activityItemGenericErrorContent}
          item={props.item}
          onShow={props.onShow}
          // By populating these, we'll emit a sentry with the error on.
          error={error}
          componentStack={componentStack}
        />
      )}
    >
      <ActivityLogItemUI {...props} />
    </Sentry.ErrorBoundary>
  );
};

const ActivityLogItemUI = ({
  incident,
  isDisabledOption,
  mode,
  item,
  zoomImageSource,
  className,
  onShow,
  setZoomImageSource,
}: ActivityLogItemUIProps): React.ReactElement | null => {
  // Item might be associated with a stream of this incident, in which case we should
  // load it to display the details.
  const isStreamItem = item.is_stream;

  const {
    data: { stream },
  } = useAPI(
    isStreamItem ? "streamsShow" : null,
    {
      id: item?.incident_id ?? "",
    },
    { fallbackData: { stream: undefined } },
  );

  // This appears to be how we identify whether the item represents a stream being created.
  const isStreamCreate =
    item.content.incident_update?.previous_incident_status == null &&
    !item.content.role_update;

  // If the item comes from a stream and is not the "we created a stream" event, we should show the stream details.
  const associatedStream =
    isStreamItem && !isStreamCreate && stream ? stream : undefined;

  const ActivityLogItemComponent = ActivityItemTypeToComponentMap[item.type];

  if (!ActivityLogItemComponent) {
    return null;
  }
  const constructorProps = ActivityLogItemComponent(
    incident,
    item,
    zoomImageSource,
    setZoomImageSource,
  );
  if (!constructorProps) {
    return null;
  }

  let quotedContent = constructorProps.quotedContent;
  let unquotedContent = constructorProps.unquotedContent;
  if (mode === ActivityLogItemMode.Timeline) {
    // If the activity log is attached to an item on the timeline, don't quote any content to avoid
    // the double vertical line. Instead show all content unquoted.
    unquotedContent = (
      <div className="space-y-2">
        {quotedContent}
        {constructorProps.unquotedContent}
      </div>
    );
    quotedContent = undefined;
  }
  if (isDisabledOption) {
    // We may hide all content if we're in the timeline editor.
    quotedContent = undefined;
    unquotedContent = undefined;
  }

  return (
    <ActivityLogItemUIInner
      icon={
        mode === ActivityLogItemMode.Timeline
          ? undefined
          : constructorProps.icon
      }
      iconColour={constructorProps.colour}
      title={constructorProps.title}
      actor={constructorProps.actor}
      timestamp={item.created_at}
      itemId={item.id}
      className={className}
      itemIncidentId={item.incident_id}
      isDisabledOption={isDisabledOption}
      quotedContent={quotedContent}
      unquotedContent={unquotedContent}
      associatedStream={associatedStream}
      onShow={onShow}
    />
  );
};

// ActivityLogItemUIInner is kept separate so we can use it to render errors when we can't render
// the full item.
export const ActivityLogItemUIInner = ({
  icon,
  iconColour,
  title,
  actor,
  timestamp,
  itemId,
  itemIncidentId,
  isDisabledOption,
  onShow,
  quotedContent,
  unquotedContent,
  associatedStream,
  className,
}: {
  icon?: IconEnum;
  iconColour?: ColorPaletteEnum;
  title: React.ReactNode;
  actor?: Actor;
  timestamp: Date;
  itemId: string;
  itemIncidentId: string;
  isDisabledOption?: boolean;
  onShow?: (props: { incidentId: string; itemId: string }) => void;
  quotedContent?: React.ReactNode;
  unquotedContent?: React.ReactNode;
  associatedStream?: Stream;
  className?: string;
}): React.ReactElement => {
  return (
    <li
      key={itemId}
      className={tcx(
        "p-2 pt-1 flex justify-between group overflow-hidden",
        onShow && "hover:bg-surface-secondary rounded",
        className,
      )}
    >
      <div className="flex gap-3 w-full">
        {icon && (
          <IconBadge
            icon={icon}
            color={iconColour ?? ColorPaletteEnum.Blue}
            size={IconSize.Small}
            className={tcx(isDisabledOption && "opacity-30")}
          />
        )}
        <div className="w-full space-y-2 overflow-hidden">
          <ActivityHeader
            title={title}
            actor={actor}
            timestamp={timestamp}
            itemId={itemId}
            itemIncidentId={itemIncidentId}
            onShow={onShow}
            associatedStream={associatedStream}
            isDisabledOption={isDisabledOption}
          />

          <>
            {quotedContent && (
              <div className={tcx("border-l-2 pl-3 text-content-secondary")}>
                {quotedContent}
              </div>
            )}
            {unquotedContent && (
              <div className={tcx("text-content-secondary max-w-full")}>
                {unquotedContent}
              </div>
            )}
          </>
        </div>
      </div>
    </li>
  );
};

const ActivityHeader = ({
  title,
  actor,
  timestamp,
  itemId,
  itemIncidentId,
  onShow,
  isDisabledOption,
  associatedStream,
}: {
  title: React.ReactNode;
  actor?: Actor;
  timestamp: Date;
  itemId: string;
  itemIncidentId: string;
  onShow?: (props: { incidentId: string; itemId: string }) => void;
  isDisabledOption?: boolean;
  associatedStream?: Stream;
}): React.ReactElement => {
  return (
    <div
      className={tcx(
        "flex justify-between",
        isDisabledOption && "pointer-events-none",
      )}
    >
      <div className="my-1 flex-center-y gap-2 w-full">
        <div
          className={tcx(
            "flex-center-y gap-2",
            isDisabledOption && "opacity-30",
          )}
        >
          <span className="font-semibold">{title}</span>
          {associatedStream && <StreamButton stream={associatedStream} />}
          <ActivityActorBadge actor={actor} />
          <LocalDateTime
            timestamp={timestamp}
            format="HH:mm"
            className={
              "text-xs-med pr-2 text-content-tertiary hover:!text-slate-300"
            }
          />
        </div>
        {isDisabledOption && (
          <Badge
            theme={BadgeTheme.Success}
            icon={IconEnum.Checkmark}
            className="ml-auto"
          >
            Added
          </Badge>
        )}
      </div>
      {onShow && !isDisabledOption && (
        <Tooltip content="Add to timeline" delayDuration={0}>
          <Button
            analyticsTrackingId={"activity-log-add-item-to-timeline"}
            onClick={() => onShow({ incidentId: itemIncidentId, itemId })}
            size={ButtonSize.Small}
            className={tcx("p-1.5 hidden group-hover:block")}
          >
            <Icon id={IconEnum.Add} size={IconSize.Small} />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

const StreamButton = ({ stream }: { stream: Stream }): React.ReactElement => {
  // The underlying url logic is the same as navigating to modals
  const navigateToDrawer = useNavigateToModal();

  return (
    <Button
      theme={ButtonTheme.Unstyled}
      className="ml-2 flex gap-1 items-center min-w-0 shrink max-w-40"
      onClick={() => navigateToDrawer(`streams/${stream.external_id}`)}
      analyticsTrackingId="view-stream-from-timeline"
    >
      <Icon
        size={IconSize.Small}
        id={IconEnum.GitBranch}
        className="text-content-secondary"
      />
      <div className="text-xs-med text-content-secondary truncate">
        {stream.name}
      </div>
    </Button>
  );
};

const ActivityActorBadge = ({
  actor,
}: {
  actor?: Actor;
}): React.ReactElement | null => {
  if (!actor) {
    return null;
  }

  let icon: React.ReactElement | null = null;
  let tooltipContent: string | null = null;

  // If it's a user, show an avatar.
  if (actor.user) {
    icon = <Avatar url={actor.user.avatar_url} size={IconSize.Medium} />;
    tooltipContent = actor.user.name;
  } else if (actor.workflow) {
    icon = <Icon id={IconEnum.Workflows} size={IconSize.Small} />;
    tooltipContent = "Workflow: " + actor.workflow.name;
  } else if (actor.api_key) {
    icon = <Icon id={IconEnum.Key} size={IconSize.Small} />;
    tooltipContent = "API Key: " + actor.api_key.name;
  } else if (actor.alert) {
    icon = <Icon id={IconEnum.Alert} size={IconSize.Small} />;
    if (actor.alert.source_type) {
      tooltipContent =
        "Alert from " + getAlertSourceLabel(actor.alert.source_type);
    }
  } else {
    icon = <Icon id={IconEnum.Automation} size={IconSize.Small} />;
    tooltipContent = "An automation";
  }

  if (tooltipContent) {
    return (
      <Tooltip
        analyticsTrackingId={null}
        content={tooltipContent}
        delayDuration={0}
      >
        <Button
          theme={ButtonTheme.Unstyled}
          analyticsTrackingId={"activity-actor"}
        >
          {icon}
        </Button>
      </Tooltip>
    );
  }

  return <div>{icon}</div>;
};

const getAlertSourceLabel = (sourceType: string): string => {
  // Replace underscores with strings, and then capitalize the first letter of each word.
  return sourceType
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};
