import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import {
  ActivityLogEntry,
  Incident,
  MicrosoftTeamsPin,
} from "src/contexts/ClientContext";

export const ActivityItemMicrosoftTeamsMessagePinned = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  const pin = item.content.microsoft_teams_pin;

  if (!pin) {
    throw new Error("malformed timeline item");
  }

  const actor = pin.message_user && {
    user: pin.message_user,
  };

  return {
    title: "Message pinned",
    actor: actor,
    icon: IconEnum.Pin,
    colour: ColorPaletteEnum.Pink,
    unquotedContent: (
      <ActivityItemMicrosoftTeamsMessagePinnedContent pin={pin} />
    ),
  };
};

const ActivityItemMicrosoftTeamsMessagePinnedContent = ({
  pin,
}: {
  pin: MicrosoftTeamsPin;
}) => {
  if (pin.message_enrichment_failure) {
    return <>{pin.message_enrichment_failure}</>;
  }

  if (!pin.message) {
    return <>The content of this message is unavailable.</>;
  }

  return (
    <>
      <div className="text-sm-bold">{pin.message.subject}</div>
      <div>{pin.message.content}</div>
    </>
  );
};
