/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivityLogContent,
    ActivityLogContentFromJSON,
    ActivityLogContentFromJSONTyped,
    ActivityLogContentToJSON,
} from './ActivityLogContent';
import {
    AssociatedUser,
    AssociatedUserFromJSON,
    AssociatedUserFromJSONTyped,
    AssociatedUserToJSON,
} from './AssociatedUser';

/**
 * 
 * @export
 * @interface ActivityLogEntry
 */
export interface ActivityLogEntry {
    /**
     * 
     * @type {AssociatedUser}
     * @memberof ActivityLogEntry
     */
    associated_users: AssociatedUser;
    /**
     * 
     * @type {ActivityLogContent}
     * @memberof ActivityLogEntry
     */
    content: ActivityLogContent;
    /**
     * Timestamp when the activity log entry was created
     * @type {Date}
     * @memberof ActivityLogEntry
     */
    created_at: Date;
    /**
     * Unique identifier of the activity log entry
     * @type {string}
     * @memberof ActivityLogEntry
     */
    id: string;
    /**
     * Idempotency key for the activity log entry
     * @type {string}
     * @memberof ActivityLogEntry
     */
    idempotency_key: string;
    /**
     * ID of the incident or stream
     * @type {string}
     * @memberof ActivityLogEntry
     */
    incident_id: string;
    /**
     * Whether or not this activity log entry came from a stream
     * @type {boolean}
     * @memberof ActivityLogEntry
     */
    is_stream: boolean;
    /**
     * Timestamp of when this activity log entry occurred
     * @type {Date}
     * @memberof ActivityLogEntry
     */
    occurred_at: Date;
    /**
     * Type of the activity log entry
     * @type {string}
     * @memberof ActivityLogEntry
     */
    type: ActivityLogEntryTypeEnum;
    /**
     * Timestamp when the activity log entry was last updated
     * @type {Date}
     * @memberof ActivityLogEntry
     */
    updated_at?: Date;
}

/**
* @export
* @enum {string}
*/
export enum ActivityLogEntryTypeEnum {
    SlackPin = 'slack_pin',
    MicrosoftTeamsPinnedChannelMessage = 'microsoft_teams_pinned_channel_message',
    SummaryUpdate = 'summary_update',
    RoleUpdate = 'role_update',
    Handover = 'handover',
    SlackInferSentry = 'slack_infer_sentry',
    SlackImage = 'slack_image',
    SlackMessage = 'slack_message',
    IncidentChannelJoin = 'incident_channel_join',
    IncidentChannelLeave = 'incident_channel_leave',
    IncidentEscalate = 'incident_escalate',
    ActionCreated = 'action_created',
    ActionUpdated = 'action_updated',
    FollowUpCreated = 'follow_up_created',
    FollowUpUpdated = 'follow_up_updated',
    ActionsSharedInChannel = 'actions_shared_in_channel',
    FollowUpsSharedInChannel = 'follow_ups_shared_in_channel',
    StatusChange = 'status_change',
    AtlassianStatuspageUpdate = 'atlassian_statuspage_update',
    IncidentUpdate = 'incident_update',
    CustomFieldValueUpdate = 'custom_field_value_update',
    IncidentMembershipRevoked = 'incident_membership_revoked',
    IncidentVisibilityChanged = 'incident_visibility_changed',
    IncidentRename = 'incident_rename',
    IncidentAttachmentAdded = 'incident_attachment_added',
    IncidentAttachmentRemoved = 'incident_attachment_removed',
    PagerdutyIncidentTriggered = 'pagerduty_incident_triggered',
    PagerdutyIncidentAcknowledged = 'pagerduty_incident_acknowledged',
    PagerdutyIncidentResolved = 'pagerduty_incident_resolved',
    IncidentTimestampSet = 'incident_timestamp_set',
    IncidentMerged = 'incident_merged',
    IncidentTypeChanged = 'incident_type_changed',
    StatusPageIncidentLinked = 'status_page_incident_linked',
    StatusPageIncidentUpdated = 'status_page_incident_updated',
    UserIntentDeclared = 'user_intent_declared',
    CallUrlChanged = 'call_url_changed',
    PostmortemChanged = 'postmortem_changed',
    Scrub = 'scrub',
    EscalationCreated = 'escalation_created',
    IncidentCallStarted = 'incident_call_started',
    IncidentCallEnded = 'incident_call_ended',
    IncidentCallParticipantsUpdated = 'incident_call_participants_updated',
    MicrosoftTeamsMessage = 'microsoft_teams_message'
}

export function ActivityLogEntryFromJSON(json: any): ActivityLogEntry {
    return ActivityLogEntryFromJSONTyped(json, false);
}

export function ActivityLogEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityLogEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'associated_users': AssociatedUserFromJSON(json['associated_users']),
        'content': ActivityLogContentFromJSON(json['content']),
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'idempotency_key': json['idempotency_key'],
        'incident_id': json['incident_id'],
        'is_stream': json['is_stream'],
        'occurred_at': (new Date(json['occurred_at'])),
        'type': json['type'],
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}

export function ActivityLogEntryToJSON(value?: ActivityLogEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'associated_users': AssociatedUserToJSON(value.associated_users),
        'content': ActivityLogContentToJSON(value.content),
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'idempotency_key': value.idempotency_key,
        'incident_id': value.incident_id,
        'is_stream': value.is_stream,
        'occurred_at': (value.occurred_at.toISOString()),
        'type': value.type,
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toISOString()),
    };
}

