import { marshallCustomFieldEntriesToRequestPayload } from "@incident-shared/forms/v2/CustomFieldFormElement";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  DeclareFormData,
  FormElements,
  marshallFormElementDataToIncidentForm,
  marshallIncidentResponseToFormElementData,
  marshallIncidentRolesToFormData,
  marshallTextDocumentPayload,
  useElementBindings,
} from "@incident-shared/incident-forms";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  SeverityBadge,
} from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import React, { useEffect } from "react";
import {
  FieldNamesMarkedBoolean,
  Path,
  SubmitHandler,
  useForm,
  UseFormGetValues,
  UseFormReturn,
  UseFormSetValue,
} from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import {
  CustomFieldEntryPayload,
  IncidentFormLifecycleElementBinding,
  IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum as IncidentFormType,
  IncidentsCreateRequestBody,
  IncidentsCreateRequestBodyModeEnum,
  IncidentsCreateRequestBodyVisibilityEnum as VisibilityEnum,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  IncidentType,
  IncidentTypeCreateInTriageEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIsMSTeamsTabFriendlyView } from "src/contexts/MSTeamsTabContext";
import { useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";
import { v4 as uuid } from "uuid";

import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "../../../hooks/usePrimaryCommsPlatform";
import { useIncidentManualTriageIncidentsEnabled } from "../../../utils/incident-manual-triage";
import { AliasedSeverity } from "../../../utils/severities";
import { buildIncidentTypeOptions } from "./buildIncidentTypeOptions";
import { buildSlackTeamOptions } from "./buildSlackTeamOptions";
import { getRestrictedElementBindings } from "./helpers";
import {
  IncidentCrudResourceTypes,
  useAllStatuses,
  useStatusesForIncidentType,
} from "./useIncidentCrudResources";

const makeRequestPayload = (
  f: DeclareFormData,
  ourElementBindings: IncidentFormLifecycleElementBinding[],
  {
    triageStatus,
    activeStatus,
  }: {
    triageStatus: IncidentStatus;
    activeStatus: IncidentStatus;
  },
  touchedFields: Partial<Readonly<FieldNamesMarkedBoolean<DeclareFormData>>>,
): IncidentsCreateRequestBody => {
  const incidentFormRequestBody = marshallFormElementDataToIncidentForm(
    ourElementBindings,
    f,
    touchedFields,
  );

  return {
    ...f,
    ...incidentFormRequestBody,
    incident_status_id:
      f.active_or_triage === "triage" ? triageStatus.id : activeStatus.id,
    summary: marshallTextDocumentPayload(f.summary),
  };
};

export const getSeverityOptions = (
  severities: AliasedSeverity[],
): SelectOption[] => {
  // When you create a new severity it is assigned a rank of -1, but if we try to sort
  // by strings like "-1", "0", "1", "2" it will rank "-1" higher than "2".
  // So we have a gross hack to bump the ranks for sort keys so they start at 0.
  const minRank = _.minBy(severities, "rank")?.rank;
  if (minRank === undefined) return [];

  let adjustBy = 0;
  if (minRank < 0) {
    adjustBy = -minRank;
  }
  return severities.map((sev) => ({
    value: sev.id,
    label: sev.name,
    sort_key: (sev.rank + adjustBy).toString().padStart(4),
    iconComponent: (
      <SeverityBadge
        severity={sev}
        iconOnly
        naked
        className="mr-1"
        size={BadgeSize.Small}
      />
    ),
  }));
};

export const ActiveIncidentCreateForm = ({
  formMethods,
  settings,
  slackTeamConfigs,
  incidentTypes,
  elementBindings,
  customFieldEntryPayloads,
}: {
  formMethods: UseFormReturn<DeclareFormData>;
  elementBindings: IncidentFormLifecycleElementBinding[];
  customFieldEntryPayloads: CustomFieldEntryPayload[];
} & IncidentCrudResourceTypes): React.ReactElement => {
  const isTest =
    formMethods.watch("mode") === IncidentsCreateRequestBodyModeEnum.Test;
  const isIncidentManualTriageEnabled =
    useIncidentManualTriageIncidentsEnabled(settings);

  const defaultIncidentType = incidentTypes.find(
    (it) => it.is_default === true,
  );
  if (!defaultIncidentType) {
    throw new Error("could not find default incident type");
  }

  const { identity } = useIdentity();
  const slackTeamIds = identity.slack_info?.team_ids;
  const accessibleSlackTeamConfigs = slackTeamConfigs.filter(
    (team) =>
      slackTeamIds === undefined ||
      slackTeamIds.length === 0 ||
      slackTeamIds.includes(team.slack_team_id),
  );

  const { watch } = formMethods;

  const [selectedIncidentTypeId, selectedSlackTeamId, activeOrTriage] = watch([
    "incident_type_id",
    "slack_team_id",
    "active_or_triage",
  ]);

  const { selectedIncidentType } = buildIncidentTypeOptions({
    selectedIncidentTypeID: selectedIncidentTypeId,
    incidentTypes,
    includeNotYetKnownOption: isIncidentManualTriageEnabled ? true : false,
  });

  let noPermissionToCreateIncidentType = false;
  if (selectedIncidentType?.restricted && !isIncidentManualTriageEnabled) {
    noPermissionToCreateIncidentType = true;
    elementBindings = getRestrictedElementBindings(elementBindings);
  }

  const { slackTeamOptions } = buildSlackTeamOptions({
    selectedSlackTeamID: selectedSlackTeamId,
    slackTeamConfigs: accessibleSlackTeamConfigs,
  });

  const { showArticle } = useIntercom();

  const {
    permanentSlackEnterpriseGridIncidentsShareIncidentsAcrossAllWorkspaces,
  } = useFlags();

  return (
    <>
      {isTest ? (
        <Callout theme={CalloutTheme.Info}>
          <p>
            Test incidents are a safe space for you to try incident.io without
            disturbing your colleagues. They won&rsquo;t appear in the incidents
            list or insights, and announcement rules and workflows won&rsquo;t
            run.{" "}
            <Button
              analyticsTrackingId={null}
              onClick={() => showArticle(5947934)}
              theme={ButtonTheme.Link}
            >
              Learn more
            </Button>
            .
          </p>
        </Callout>
      ) : (
        // Hack to add a bit of extra space at the top of the active incident create modal
        <div />
      )}
      {/* Slack workspace */}
      {slackTeamOptions.length > 1 && (
        <div>
          <StaticSingleSelectV2<DeclareFormData>
            formMethods={formMethods}
            name={"slack_team_id"}
            options={slackTeamOptions}
            placeholder="Choose a Slack workspace"
            isClearable={false}
            required={"Please select a Slack workspace"}
            helptext="Which Slack workspace should the incident channel be created for?"
            label={"Slack workspace"}
          />
          {permanentSlackEnterpriseGridIncidentsShareIncidentsAcrossAllWorkspaces && (
            /* Callout that incident will be shared across workspaces */
            <Callout theme={CalloutTheme.Info}>
              The incident channel will be automatically shared across
              workspaces that incident.io has been installed in
            </Callout>
          )}
        </div>
      )}
      {/* Incident form elements (roles, custom fields, timestamps, summary) */}
      <FormElements
        elementBindings={elementBindings}
        formMethods={formMethods}
        selectedIncidentType={selectedIncidentType}
        incidentFormType={IncidentFormType.Declare}
        activeOrTriage={activeOrTriage}
        customFieldEntryPayloads={customFieldEntryPayloads}
      />
      {noPermissionToCreateIncidentType && (
        <Callout theme={CalloutTheme.Danger}>
          You don&apos;t have permission to declare incidents of type{" "}
          {selectedIncidentType?.name}
        </Callout>
      )}
    </>
  );
};

export const useActiveIncidentCreateForm = ({
  incidentTypes,
  settings,
  severities,
  customFields,
  incidentTimestamps,
  incidentRoles,
  slackTeamConfigs,
  onSuccess,
  mode,
  prefillDefaultValues,
  escalationId,
}: IncidentCrudResourceTypes & {
  mode:
    | IncidentsCreateRequestBodyModeEnum.Standard
    | IncidentsCreateRequestBodyModeEnum.Test;
  onSuccess: () => void;
  prefillDefaultValues: Partial<DeclareFormData>;
  escalationId?: string;
}): {
  formMethods: UseFormReturn<DeclareFormData>;
  saving: boolean;
  onSubmit: SubmitHandler<DeclareFormData>;
  genericError: string | undefined;
  confirmButtonText: string;
  elementBindings: IncidentFormLifecycleElementBinding[];
  customFieldEntryPayloads: CustomFieldEntryPayload[];
  disabled: boolean;
} => {
  const navigate = useOrgAwareNavigate();
  const primaryCommsPlatform = usePrimaryCommsPlatform();

  const { allStatuses } = useAllStatuses();
  const triageStatus = allStatuses.filter(
    (x) => x.category === IncidentStatusCategoryEnum.Triage,
  )[0];
  const minSeverityId = _.minBy(severities, "rank")?.id;

  const isIncidentManualTriageEnabled =
    useIncidentManualTriageIncidentsEnabled(settings);

  const defaultIncidentType = incidentTypes.find(
    (it) => it.is_default === true,
  );
  if (!defaultIncidentType) {
    throw new Error("could not find default incident type");
  }

  const slackTeamIds = useIdentity().identity.slack_info?.team_ids;
  const accessibleSlackTeamConfigs = slackTeamConfigs.filter(
    (team) =>
      slackTeamIds === undefined ||
      slackTeamIds.length === 0 ||
      slackTeamIds.includes(team.slack_team_id),
  );

  const elementFormDefaultValues = marshallIncidentResponseToFormElementData(
    customFields,
    incidentTimestamps,
    incidentRoles,
    {
      incident_role_assignments: [],
      incident_timestamps: [],
      custom_field_entries: [],
    },
  );

  const defaultActiveStatus = allStatuses.filter(
    (x) => x.category === IncidentStatusCategoryEnum.Active,
  )[0];
  const defaultValues: Partial<DeclareFormData> = {
    mode,
    severity_id: minSeverityId,
    idempotency_key: uuid(),
    visibility: getDefaultVisibility(defaultIncidentType, primaryCommsPlatform),
    ...elementFormDefaultValues,
    incident_type_id: defaultIncidentType.id,
    slack_team_id:
      accessibleSlackTeamConfigs.length === 1
        ? accessibleSlackTeamConfigs[0].slack_team_id
        : undefined,
    ...prefillDefaultValues, // this overrides elementFormDefaultValues custom fields even when we don't to
    custom_field_entries: {
      ...elementFormDefaultValues.custom_field_entries, // so we put them back here
      ...prefillDefaultValues.custom_field_entries,
    },
  };

  const formMethods = useForm<DeclareFormData>({
    defaultValues,
  });
  const { watch, setError, setValue, getValues } = formMethods;

  const [
    selectedIncidentTypeId,
    selectedCustomFieldEntries,
    selectedIncidentRoleAssignments,
    selectedSeverityId,
    activeOrTriage,
  ] = watch([
    "incident_type_id",
    "custom_field_entries",
    "incident_role_assignments",
    "severity_id",
    "active_or_triage",
  ]);

  const { statuses } = useStatusesForIncidentType({
    incidentTypeId: selectedIncidentTypeId,
  });
  // Find the first active status that's valid for this incident type.
  const activeStatus = statuses.find(
    (status) => status.category === IncidentStatusCategoryEnum.Active,
  );

  const { selectedIncidentType, hasSelectedIncidentTypeDontKnow } =
    buildIncidentTypeOptions({
      selectedIncidentTypeID: selectedIncidentTypeId,
      incidentTypes,
      includeNotYetKnownOption: isIncidentManualTriageEnabled ? true : false,
    });

  // Update the visibility field everytime we change to/from an incident
  // type that requires private incidents.
  usePrivateIncidentsOnly({
    setValue,
    selectedIncidentType,
    primaryCommsPlatform,
  });

  // Update the selected radio button if the incident type is restricted
  // (ie: triage only)
  useApplyRestrictedType({
    selectedIncidentType,
    getValues,
    setValue,
  });

  const { identity } = useIdentity();

  const incidentTypeShouldCreateInTriage =
    selectedIncidentType?.create_in_triage ===
      IncidentTypeCreateInTriageEnum.Always ||
    (selectedIncidentType?.restricted && isIncidentManualTriageEnabled);

  const customFieldEntries = marshallCustomFieldEntriesToRequestPayload(
    customFields,
    formMethods.formState.touchedFields,
    selectedCustomFieldEntries,
  );
  const incidentRoleAssignments = marshallIncidentRolesToFormData({
    incidentRoles,
    formAssignments: selectedIncidentRoleAssignments,
  });

  const selectedIncidentStatusId =
    activeOrTriage === "triage"
      ? triageStatus.id
      : activeStatus?.id ?? defaultActiveStatus?.id;

  const elementBindingsPayload = {
    incident_form_type: IncidentFormType.Declare,
    incident_type_id: selectedIncidentType?.id,
    incident_status_id: selectedIncidentStatusId,
    severity_id: selectedSeverityId,
    custom_field_entries: customFieldEntries,
    incident_role_assignments: incidentRoleAssignments,
    mode: mode,
    show_all_elements_override: false,
  };

  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const { elementBindings } = useElementBindings({
    payload: elementBindingsPayload,
    setValue,
    initialValues: prefillDefaultValues,
    touchedFields: formMethods.formState.touchedFields,
    manualEdits: [],
  });

  const isInMsTeamsView = useIsMSTeamsTabFriendlyView();

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsList",
    {},
    async (apiClient, formData: DeclareFormData) => {
      // If the incident type starts in triage, we'll hide the active vs triage input.
      // But we use the active status as our default on the form, so we need to overwrite it here.
      if (incidentTypeShouldCreateInTriage && isIncidentManualTriageEnabled) {
        formData.active_or_triage = "triage";
      }
      if (hasSelectedIncidentTypeDontKnow) {
        formData.active_or_triage = "triage";
        formData.visibility = VisibilityEnum.Public;
        // We shouldn't pass the incident_type_id to the backend if the user
        // selected "I don't know" for the incident type, since this is not
        // a valid incident type ID.
        delete formData.incident_type_id;
        // If the user selected "I don't know" for the incident type, we
        // should not pass the severity ID to the backend either, since
        // we don't know the severity of the incident.
        delete formData.severity_id;
      }

      if (!activeStatus) {
        throw new Error("unable to find active status");
      }

      const { incident } = await apiClient.incidentsCreate({
        createRequestBody: makeRequestPayload(
          { ...formData, escalation_id: escalationId },
          elementBindings,
          {
            triageStatus,
            activeStatus,
          },
          formMethods.formState.touchedFields,
        ),
      });
      if (isInMsTeamsView && incident.ms_teams_channel_url) {
        location.href = incident.ms_teams_channel_url.replace(
          "https://",
          "msteams://",
        );
      } else {
        navigate(
          `/${identity?.organisation_slug}/incidents/${incident.external_id}`,
        );
      }
    },
    {
      onSuccess: () => {
        refreshIncidentList();
        onSuccess();
      },
      setError: (path, error) => {
        if (!isCustomFieldError(path)) {
          setError(path, error);
          return;
        }

        const requestData = makeRequestPayload(
          getValues(),
          elementBindings,
          {
            triageStatus,
            activeStatus: activeStatus as IncidentStatus,
          },
          formMethods.formState.touchedFields,
        );

        setError(
          getCustomFieldErrorPath<DeclareFormData>(
            path,
            requestData.custom_field_entries ?? [],
          ),
          error,
        );
      },
    },
  );

  const disabled = !!(
    !isIncidentManualTriageEnabled && selectedIncidentType?.restricted
  );

  return {
    formMethods,
    saving,
    onSubmit,
    genericError,
    confirmButtonText: "Declare",
    elementBindings,
    customFieldEntryPayloads: customFieldEntries,
    disabled,
  };
};

// Takes the path of an API error, looks up in the index of the corresponding
// custom field, and replaces the index with the custom field ID.
export function getCustomFieldErrorPath<FormType>(
  path: string,
  customFieldPayload: CustomFieldEntryPayload[],
): Path<FormType> {
  const namePath = path.split(".");
  // We need to translate the second part into a custom field ID before
  // giving this back to useForm
  const fieldIdx = parseInt(namePath[1]);
  namePath[1] = customFieldPayload[fieldIdx]?.custom_field_id;
  return namePath.join(".") as unknown as Path<FormType>;
}

export const isCustomFieldError = (path: string): boolean =>
  path.split(".")[0] === "custom_field_entries";

const usePrivateIncidentsOnly = ({
  selectedIncidentType,
  setValue,
  primaryCommsPlatform,
}: {
  selectedIncidentType: IncidentType | undefined;
  setValue: UseFormSetValue<DeclareFormData>;
  primaryCommsPlatform: CommsPlatform | undefined;
}) => {
  const mustBePrivate = selectedIncidentType?.private_incidents_only ?? false;
  const newDefaultVisibility = getDefaultVisibility(
    selectedIncidentType,
    primaryCommsPlatform,
  );
  useEffect(() => {
    if (newDefaultVisibility) {
      setValue<"visibility">("visibility", newDefaultVisibility);
    }
  }, [mustBePrivate, setValue, newDefaultVisibility]);
};

const useApplyRestrictedType = ({
  selectedIncidentType,
  getValues,
  setValue,
}: {
  selectedIncidentType: IncidentType | undefined;
  getValues: UseFormGetValues<DeclareFormData>;
  setValue: UseFormSetValue<DeclareFormData>;
}) => {
  const isRestricted = selectedIncidentType?.restricted;
  useEffect(() => {
    if (!isRestricted) {
      return;
    }

    if (getValues("active_or_triage") === "triage") {
      return;
    }

    // If the incident type is restricted, force the user to use triage.
    setValue<"active_or_triage">("active_or_triage", "triage");
  }, [isRestricted, setValue, getValues]);
};

const getDefaultVisibility = (
  selectedIncidentType: IncidentType | undefined,
  primaryCommsPlatform: CommsPlatform | undefined,
): VisibilityEnum | undefined => {
  // If the incident type is private, default to private visibility.
  if (selectedIncidentType?.private_incidents_only) {
    return VisibilityEnum.Private;
  }

  // If the platform is Teams, set no default, we want to force the user to choose.
  if (primaryCommsPlatform === CommsPlatform.MSTeams) {
    return undefined;
  }

  // Otherwise, default to public visibility.
  return VisibilityEnum.Public;
};
