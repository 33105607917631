import "reactflow/dist/style.css";

import { AlertRoute, AlertSourceConfig } from "@incident-io/api";
import { useResize } from "@incident-io/status-page-ui/use-resize";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { GenericErrorMessage, Loader } from "@incident-ui";
import { Searcher, sortKind } from "fast-fuzzy";
import { AnimatePresence } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, {
  Edge,
  Node,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "reactflow";
import { useAPI } from "src/utils/swr";

import { tcx } from "../../../utils/tailwind-classes";
import {
  AlertAttributesCreateEditDrawer,
  AlertAttributesDrawerProvider,
} from "../attributes/AlertAttributesCreateEditDrawer";
import { shouldShowAlertSourcesEmptyState } from "../common/shouldShowAlertSourcesEmptyState";
import {
  PrioritiesCreateEditDrawer,
  PrioritiesDrawerProvider,
} from "../priorities/PrioritiesCreateEditDrawer";
import { makeRouteHeaderNodeData } from "./AlertRoutesHeader";
import { AlertsConfigurationEdge } from "./AlertsConfigurationEdge";
import { AlertsConfigurationNode } from "./AlertsConfigurationNode";
import { makeSourceHeaderNodeData } from "./AlertSourcesHeader";
import { drawEdges } from "./helpers/drawEdges";
import { drawNodes } from "./helpers/drawNodes";
import { drawStackedNodes } from "./helpers/drawStackedNodes";
import {
  handleEdgeOpacityOnHover,
  handleNodeOpacityOnHover,
} from "./helpers/handleOpacityOnHover";
import { hasSourcesAndRoutesCrossover } from "./helpers/hasSourcesAndRoutesCrossover";

const nodeTypes = { alertsConfigurationNode: AlertsConfigurationNode };
const edgeTypes = { alertsConfigurationEdge: AlertsConfigurationEdge };

export const AlertsConfigurationPage = () => {
  const navigate = useOrgAwareNavigate();

  const ref = useRef<HTMLDivElement>(null);
  const { width: containerWidth } = useResize(ref);

  const {
    data: { alert_source_configs },
    isLoading: configsLoading,
    error: sourceConfigsError,
  } = useAPI("alertsListSourceConfigs", undefined, {
    fallbackData: { alert_source_configs: [] },
  });

  const {
    data: { alert_routes },
    isLoading: alertRoutesLoading,
    error: alertRoutesError,
  } = useAPI("alertRoutesListAlertRoutes", undefined, {
    fallbackData: { alert_routes: [] },
  });

  if (configsLoading || alertRoutesLoading) {
    return <Loader />;
  }

  if (sourceConfigsError || alertRoutesError) {
    return (
      <GenericErrorMessage error={sourceConfigsError || alertRoutesError} />
    );
  }

  const shouldShowEmptyState = shouldShowAlertSourcesEmptyState(
    alert_source_configs,
    alert_routes || [],
  );

  if (shouldShowEmptyState) {
    navigate("/alerts/get-started");
  }

  return (
    <div ref={ref} className="min-w-[1200px]">
      <AlertsConfigurationPageFlow
        alert_source_configs={alert_source_configs}
        alert_routes={alert_routes}
        containerWidth={containerWidth || window.innerWidth}
      />
    </div>
  );
};

export const AlertsConfigurationPageFlow = ({
  alert_source_configs,
  alert_routes,
  containerWidth,
}: {
  alert_source_configs: AlertSourceConfig[];
  alert_routes: AlertRoute[];
  containerWidth: number;
}) => {
  const { forceAlertsConfigurationGrouping } = useFlags();

  const [searchSources, setSearchSources] = useState("");
  const [searchRoutes, setSearchRoutes] = useState("");

  const [showAttributesDrawer, setShowAttributesDrawer] = useState(false);
  const [showPrioritiesDrawer, setShowPrioritiesDrawer] = useState(false);

  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [height, setHeight] = useState(0);

  const [hoveredEdge, setHoveredEdge] = useState<Edge | undefined>();
  const [hoveredNode, setHoveredNode] = useState<Node | undefined>();

  // Filter our the sources that don't match the search term in the source header
  const sourceSearcher = useMemo(
    () =>
      new Searcher(alert_source_configs, {
        keySelector: (src) => src.name,
        sortBy: sortKind.insertOrder,
        threshold: 0.8,
      }),
    [alert_source_configs],
  );

  const filteredAlertSources = useMemo(
    () =>
      searchSources
        ? sourceSearcher.search(searchSources)
        : alert_source_configs,
    [searchSources, sourceSearcher, alert_source_configs],
  );

  // Filter out the routes that don't match the search term in the route header
  const routeSearcher = useMemo(
    () =>
      new Searcher(alert_routes, {
        keySelector: (route) => route.name,
        sortBy: sortKind.insertOrder,
        threshold: 0.8,
      }),
    [alert_routes],
  );

  const filteredAlertRoutes = useMemo(
    () => (searchRoutes ? routeSearcher.search(searchRoutes) : alert_routes),
    [searchRoutes, routeSearcher, alert_routes],
  );

  // We pass this parameter separately as we don't want our empty state to show if someone's
  // filtered down their routes.
  const hasNoAlertRoutes = alert_routes.length === 0;

  // The width at which we should switch to the stacked view
  const minWidth = 750;

  const hasComplexSetup =
    !forceAlertsConfigurationGrouping &&
    hasSourcesAndRoutesCrossover(filteredAlertSources);

  const sourceHeaderNodeData = makeSourceHeaderNodeData(
    searchSources,
    setSearchSources,
  );
  const routeHeaderNodeData = makeRouteHeaderNodeData(
    alert_source_configs,
    alert_routes,
    searchRoutes,
    setSearchRoutes,
  );

  // Draw the nodes and update them when the container width changes - if too small we stack them.
  const updateNodes = useCallback(() => {
    const { nodes: newNodes, height: newHeight } =
      containerWidth < minWidth
        ? drawStackedNodes({
            alertSources: filteredAlertSources,
            alertRoutes: filteredAlertRoutes,
            containerWidth: containerWidth,
            sourceHeaderData: sourceHeaderNodeData,
            routeHeaderData: routeHeaderNodeData,
            hasNoAlertRoutes,
          })
        : drawNodes({
            alertSources: filteredAlertSources,
            alertRoutes: filteredAlertRoutes,
            containerWidth: containerWidth,
            sourceHeaderData: sourceHeaderNodeData,
            routeHeaderData: routeHeaderNodeData,
            hasNoAlertRoutes,
            hasComplexSetup,
          });

    setNodes(newNodes);
    setHeight(newHeight);
  }, [
    containerWidth,
    filteredAlertSources,
    filteredAlertRoutes,
    hasNoAlertRoutes,
    sourceHeaderNodeData,
    routeHeaderNodeData,
    hasComplexSetup,
    setNodes,
    setHeight,
  ]);

  useEffect(() => {
    updateNodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerWidth, filteredAlertSources, filteredAlertRoutes]);

  // Draw the edges and update them when the container width changes - if too small we don't show edges.
  const updateEdges = useCallback(() => {
    const initialEdges =
      containerWidth < minWidth
        ? []
        : drawEdges({
            filteredAlertSources: filteredAlertSources,
            filteredAlertRoutes: filteredAlertRoutes,
            hasNoAlertRoutes,
          });
    setEdges(initialEdges);
  }, [
    containerWidth,
    filteredAlertSources,
    filteredAlertRoutes,
    hasNoAlertRoutes,
    setEdges,
  ]);

  useEffect(() => {
    updateEdges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredAlertSources,
    filteredAlertRoutes,
    hasNoAlertRoutes,
    containerWidth,
  ]);

  // Add opacity to nodes and edges on hovers, but don't do it on small screens as we don't want interactivity then.
  useEffect(() => {
    if (containerWidth < minWidth) {
      return;
    }
    setNodes((nds) =>
      nds.map(handleNodeOpacityOnHover(hoveredNode, hoveredEdge)),
    );
    setEdges((eds) =>
      eds.map(
        handleEdgeOpacityOnHover(hasComplexSetup, hoveredNode, hoveredEdge),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasComplexSetup, hoveredEdge, hoveredNode, containerWidth, minWidth]);

  return (
    <AlertAttributesDrawerProvider
      open={showAttributesDrawer}
      setOpen={setShowAttributesDrawer}
    >
      <PrioritiesDrawerProvider
        open={showPrioritiesDrawer}
        setOpen={setShowPrioritiesDrawer}
      >
        <div
          className={tcx(
            "px-1 pb-6",
            "bg-slate-50 bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]",
          )}
        >
          <div style={{ height: `${height}px` }}>
            <ReactFlowProvider>
              <ReactFlow
                nodes={nodes}
                nodeTypes={nodeTypes}
                onNodesChange={onNodesChange}
                edges={edges}
                edgeTypes={edgeTypes}
                onEdgesChange={onEdgesChange}
                onEdgeMouseEnter={(_, edge) => {
                  setHoveredEdge(edge);
                }}
                onEdgeMouseLeave={() => {
                  setHoveredEdge(undefined);
                }}
                onEdgeMouseMove={(_, edge) => {
                  setHoveredEdge(edge);
                }}
                onNodeMouseEnter={(_, node) => {
                  if (
                    node.id !== "sources-header" &&
                    node.id !== "routes-header"
                  ) {
                    setHoveredNode(node);
                  }
                }}
                onNodeMouseMove={(_, node) => {
                  if (
                    node.id !== "sources-header" &&
                    node.id !== "routes-header"
                  ) {
                    setHoveredNode(node);
                  }
                }}
                onNodeMouseLeave={() => {
                  setHoveredNode(undefined);
                }}
                panOnDrag={false}
                zoomOnScroll={false}
                zoomOnPinch={false}
                preventScrolling={false}
                proOptions={{ hideAttribution: true }}
                nodesDraggable={false}
                nodesConnectable={false}
                nodesFocusable={false}
                edgesFocusable={false}
                autoPanOnConnect={false}
                autoPanOnNodeDrag={false}
                zoomOnDoubleClick={false}
                selectNodesOnDrag={false}
                elevateNodesOnSelect={false}
                connectOnClick={false}
              />
            </ReactFlowProvider>
          </div>
        </div>

        <AnimatePresence>
          {showPrioritiesDrawer && (
            <PrioritiesCreateEditDrawer
              onClose={() => setShowPrioritiesDrawer(false)}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showAttributesDrawer && (
            <AlertAttributesCreateEditDrawer
              onClose={() => setShowAttributesDrawer(false)}
            />
          )}
        </AnimatePresence>
      </PrioritiesDrawerProvider>
    </AlertAttributesDrawerProvider>
  );
};
