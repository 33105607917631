import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import React from "react";
import { ActivityItemAtlassianStatuspageUpdate } from "src/components/timeline/activity-items/ActivityItemAtlassianStatuspageUpdate";
import { ActivityItemCustomFieldValueUpdate } from "src/components/timeline/activity-items/ActivityItemCustomFieldValueUpdate";
import { ActivityItemEscalate } from "src/components/timeline/activity-items/ActivityItemEscalate";
import { ActivityItemIncidentAttachmentAdded } from "src/components/timeline/activity-items/ActivityItemIncidentAttachmentAdded";
import { ActivityItemIncidentAttachmentRemoved } from "src/components/timeline/activity-items/ActivityItemIncidentAttachmentRemoved";
import { ActivityItemIncidentMembershipRevoked } from "src/components/timeline/activity-items/ActivityItemIncidentMembershipRevoked";
import { ActivityItemIncidentRename } from "src/components/timeline/activity-items/ActivityItemIncidentRename";
import { ActivityItemMicrosoftTeamsMessagePinned } from "src/components/timeline/activity-items/ActivityItemMicrosoftTeamsMessagePinned";
import { ActivityItemPagerdutyIncidentAcknowledged } from "src/components/timeline/activity-items/ActivityItemPagerdutyIncidentAcknowledged";
import { ActivityItemPagerdutyIncidentResolved } from "src/components/timeline/activity-items/ActivityItemPagerdutyIncidentResolved";
import { ActivityItemPagerdutyIncidentTriggered } from "src/components/timeline/activity-items/ActivityItemPagerdutyIncidentTriggered";
import { ActivityItemRoleUpdate } from "src/components/timeline/activity-items/ActivityItemRoleUpdate";
import { ActivityItemSlackImage } from "src/components/timeline/activity-items/ActivityItemSlackImage";
import { ActivityItemSlackInferSentry } from "src/components/timeline/activity-items/ActivityItemSlackInferSentry";
import { ActivityItemSlackPin } from "src/components/timeline/activity-items/ActivityItemSlackPin";
import { ActivityItemStatusChange } from "src/components/timeline/activity-items/ActivityItemStatusChange";
import { ActivityItemStatusPageIncidentLinked } from "src/components/timeline/activity-items/ActivityItemStatusPageIncidentLinked";
import { ActivityItemStatusPageIncidentUpdated } from "src/components/timeline/activity-items/ActivityItemStatusPageIncidentUpdated";
import { ActivityItemSummaryUpdate } from "src/components/timeline/activity-items/ActivityItemSummaryUpdate";
import { ActivityItemVisibilityChanged } from "src/components/timeline/activity-items/ActivityItemVisibilityChanged";
import {
  ActivityLogEntry,
  ActivityLogEntryTypeEnum,
  Actor,
  Incident,
} from "src/contexts/ClientContext";

import { ActivityItemActionCreated } from "./ActivityItemActionCreated";
import { ActivityItemActionUpdated } from "./ActivityItemActionUpdated";
import { ActivityItemEscalationCreated } from "./ActivityItemEscalationCreated";
import { ActivityItemFollowUpCreated } from "./ActivityItemFollowUpCreated";
import { ActivityItemFollowUpUpdated } from "./ActivityItemFollowUpUpdated";
import { ActivityItemIncidentCallEnded } from "./ActivityItemIncidentCallEnded";
import { ActivityItemIncidentCallStarted } from "./ActivityItemIncidentCallStarted";
import { ActivityItemIncidentTimestampSet } from "./ActivityItemIncidentTimestampSet";
import { ActivityItemIncidentTypeChanged } from "./ActivityItemIncidentTypeChanged";
import { ActivityItemIncidentUpdateComponent } from "./ActivityItemIncidentUpdateComponent";
import { ActivityItemMerged } from "./ActivityItemMerged";

export type ActivityItemRenderProps = {
  // Title is usually like "thing happened"
  title: React.ReactNode;
  icon: IconEnum;
  // These are the general rules for colouring:
  // Red - incident reported / accepted / reopened
  // Green - incident resolved or closed
  // Blue - other incident updates
  // Cyan - pinned slack message
  // Pink - custom events
  // Purple - any other actions. This will be the majority of items!
  colour: ColorPaletteEnum;
  // The actor who did the thing. Only in very rare cases is this not present.
  actor?: Actor;
  // Contents that will be quoted underneath
  quotedContent?: React.ReactNode;
  // Contents that will appear at the bottom, not quoted (we do this for badges, links and error messages)
  unquotedContent?: React.ReactNode;
};

export type ActivityItemConstructor = (
  incident: Incident,
  item: ActivityLogEntry,
  zoomImageSource: string | undefined,
  setZoomImageSource: React.Dispatch<React.SetStateAction<string | undefined>>,
) => ActivityItemRenderProps | null;

export const ActivityItemTypeToComponentMap: {
  [key in ActivityLogEntryTypeEnum]: ActivityItemConstructor | null;
} = {
  [ActivityLogEntryTypeEnum.RoleUpdate]: ActivityItemRoleUpdate,
  [ActivityLogEntryTypeEnum.SlackInferSentry]: ActivityItemSlackInferSentry,
  [ActivityLogEntryTypeEnum.SlackPin]: ActivityItemSlackPin,
  [ActivityLogEntryTypeEnum.SummaryUpdate]: ActivityItemSummaryUpdate,
  [ActivityLogEntryTypeEnum.IncidentEscalate]: ActivityItemEscalate,
  [ActivityLogEntryTypeEnum.ActionCreated]: ActivityItemActionCreated,
  [ActivityLogEntryTypeEnum.ActionUpdated]: ActivityItemActionUpdated,
  [ActivityLogEntryTypeEnum.FollowUpCreated]: ActivityItemFollowUpCreated,
  [ActivityLogEntryTypeEnum.FollowUpUpdated]: ActivityItemFollowUpUpdated,
  [ActivityLogEntryTypeEnum.StatusChange]: ActivityItemStatusChange,
  [ActivityLogEntryTypeEnum.AtlassianStatuspageUpdate]:
    ActivityItemAtlassianStatuspageUpdate,
  [ActivityLogEntryTypeEnum.IncidentUpdate]:
    ActivityItemIncidentUpdateComponent,
  [ActivityLogEntryTypeEnum.CustomFieldValueUpdate]:
    ActivityItemCustomFieldValueUpdate,
  [ActivityLogEntryTypeEnum.IncidentMembershipRevoked]:
    ActivityItemIncidentMembershipRevoked,
  [ActivityLogEntryTypeEnum.IncidentVisibilityChanged]:
    ActivityItemVisibilityChanged,
  [ActivityLogEntryTypeEnum.SlackImage]: ActivityItemSlackImage,
  [ActivityLogEntryTypeEnum.IncidentRename]: ActivityItemIncidentRename,
  [ActivityLogEntryTypeEnum.IncidentAttachmentAdded]:
    ActivityItemIncidentAttachmentAdded,
  [ActivityLogEntryTypeEnum.IncidentAttachmentRemoved]:
    ActivityItemIncidentAttachmentRemoved,
  [ActivityLogEntryTypeEnum.PagerdutyIncidentTriggered]:
    ActivityItemPagerdutyIncidentTriggered,
  [ActivityLogEntryTypeEnum.PagerdutyIncidentAcknowledged]:
    ActivityItemPagerdutyIncidentAcknowledged,
  [ActivityLogEntryTypeEnum.PagerdutyIncidentResolved]:
    ActivityItemPagerdutyIncidentResolved,
  [ActivityLogEntryTypeEnum.IncidentTimestampSet]:
    ActivityItemIncidentTimestampSet,
  [ActivityLogEntryTypeEnum.IncidentMerged]: ActivityItemMerged,
  [ActivityLogEntryTypeEnum.IncidentTypeChanged]:
    ActivityItemIncidentTypeChanged,
  [ActivityLogEntryTypeEnum.StatusPageIncidentLinked]:
    ActivityItemStatusPageIncidentLinked,
  [ActivityLogEntryTypeEnum.StatusPageIncidentUpdated]:
    ActivityItemStatusPageIncidentUpdated,
  [ActivityLogEntryTypeEnum.EscalationCreated]: ActivityItemEscalationCreated,
  [ActivityLogEntryTypeEnum.IncidentCallStarted]:
    ActivityItemIncidentCallStarted,
  [ActivityLogEntryTypeEnum.IncidentCallEnded]: ActivityItemIncidentCallEnded,
  [ActivityLogEntryTypeEnum.MicrosoftTeamsPinnedChannelMessage]:
    ActivityItemMicrosoftTeamsMessagePinned,
  [ActivityLogEntryTypeEnum.Handover]: null, // TODO [PINC-3164]: we used to show this when rolled up, what should we do now?
  [ActivityLogEntryTypeEnum.PostmortemChanged]: () => null, // TODO [PINC-3198]: stop hiding this in the backend
  // These items are hidden on the backend interface, so we don't need front end components for them.
  [ActivityLogEntryTypeEnum.ActionsSharedInChannel]: () => null,
  [ActivityLogEntryTypeEnum.Scrub]: () => null,
  [ActivityLogEntryTypeEnum.IncidentCallParticipantsUpdated]: () => null,
  [ActivityLogEntryTypeEnum.CallUrlChanged]: () => null,
  [ActivityLogEntryTypeEnum.IncidentChannelJoin]: () => null,
  [ActivityLogEntryTypeEnum.IncidentChannelLeave]: () => null,
  [ActivityLogEntryTypeEnum.FollowUpsSharedInChannel]: () => null,
  [ActivityLogEntryTypeEnum.UserIntentDeclared]: () => null,
  [ActivityLogEntryTypeEnum.SlackMessage]: () => null, // Soon!
  [ActivityLogEntryTypeEnum.MicrosoftTeamsMessage]: () => null, // Soon!
};
