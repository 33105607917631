/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExploDashboardInfo,
    ExploDashboardInfoFromJSON,
    ExploDashboardInfoFromJSONTyped,
    ExploDashboardInfoToJSON,
} from './ExploDashboardInfo';
import {
    ExploDashboardWithDrilldownInfo,
    ExploDashboardWithDrilldownInfoFromJSON,
    ExploDashboardWithDrilldownInfoFromJSONTyped,
    ExploDashboardWithDrilldownInfoToJSON,
} from './ExploDashboardWithDrilldownInfo';
import {
    PanelVariable,
    PanelVariableFromJSON,
    PanelVariableFromJSONTyped,
    PanelVariableToJSON,
} from './PanelVariable';
import {
    TextInfo,
    TextInfoFromJSON,
    TextInfoFromJSONTyped,
    TextInfoToJSON,
} from './TextInfo';

/**
 * 
 * @export
 * @interface AvailablePanel
 */
export interface AvailablePanel {
    /**
     * Description of the panel to add
     * @type {string}
     * @memberof AvailablePanel
     */
    add_panel_description: string;
    /**
     * Name of the panel to add
     * @type {string}
     * @memberof AvailablePanel
     */
    add_panel_name: string;
    /**
     * 
     * @type {ExploDashboardInfo}
     * @memberof AvailablePanel
     */
    explo_dashboard?: ExploDashboardInfo;
    /**
     * 
     * @type {ExploDashboardWithDrilldownInfo}
     * @memberof AvailablePanel
     */
    explo_dashboard_with_drilldown?: ExploDashboardWithDrilldownInfo;
    /**
     * Group to display the panel in
     * @type {string}
     * @memberof AvailablePanel
     */
    group: AvailablePanelGroupEnum;
    /**
     * Unique identifier for this panel in this dashboard (only used for custom dashboards)
     * @type {string}
     * @memberof AvailablePanel
     */
    key?: string;
    /**
     * Unique computer-readable name of the panel
     * @type {string}
     * @memberof AvailablePanel
     */
    name: string;
    /**
     * Type of the panel
     * @type {string}
     * @memberof AvailablePanel
     */
    panel_type: AvailablePanelPanelTypeEnum;
    /**
     * 
     * @type {TextInfo}
     * @memberof AvailablePanel
     */
    text?: TextInfo;
    /**
     * Variables for the panel
     * @type {Array<PanelVariable>}
     * @memberof AvailablePanel
     */
    variables: Array<PanelVariable>;
}

/**
* @export
* @enum {string}
*/
export enum AvailablePanelGroupEnum {
    AtAGlance = 'At a glance',
    Teams = 'Teams',
    TimeSpentOnIncidents = 'Time Spent on Incidents',
    Mttx = 'MTTX',
    PagerLoad = 'Pager Load',
    FollowUps = 'Follow-ups',
    PostIncidentFlow = 'Post-Incident flow',
    Homepage = 'Homepage'
}/**
* @export
* @enum {string}
*/
export enum AvailablePanelPanelTypeEnum {
    ExploDashboardWithDrilldown = 'explo_dashboard_with_drilldown',
    ExploDashboard = 'explo_dashboard',
    Trends = 'trends',
    Text = 'text'
}

export function AvailablePanelFromJSON(json: any): AvailablePanel {
    return AvailablePanelFromJSONTyped(json, false);
}

export function AvailablePanelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailablePanel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'add_panel_description': json['add_panel_description'],
        'add_panel_name': json['add_panel_name'],
        'explo_dashboard': !exists(json, 'explo_dashboard') ? undefined : ExploDashboardInfoFromJSON(json['explo_dashboard']),
        'explo_dashboard_with_drilldown': !exists(json, 'explo_dashboard_with_drilldown') ? undefined : ExploDashboardWithDrilldownInfoFromJSON(json['explo_dashboard_with_drilldown']),
        'group': json['group'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'name': json['name'],
        'panel_type': json['panel_type'],
        'text': !exists(json, 'text') ? undefined : TextInfoFromJSON(json['text']),
        'variables': ((json['variables'] as Array<any>).map(PanelVariableFromJSON)),
    };
}

export function AvailablePanelToJSON(value?: AvailablePanel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'add_panel_description': value.add_panel_description,
        'add_panel_name': value.add_panel_name,
        'explo_dashboard': ExploDashboardInfoToJSON(value.explo_dashboard),
        'explo_dashboard_with_drilldown': ExploDashboardWithDrilldownInfoToJSON(value.explo_dashboard_with_drilldown),
        'group': value.group,
        'key': value.key,
        'name': value.name,
        'panel_type': value.panel_type,
        'text': TextInfoToJSON(value.text),
        'variables': ((value.variables as Array<any>).map(PanelVariableToJSON)),
    };
}

