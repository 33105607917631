import { CustomDashboard, ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  FullPageEmptyState,
  GenericErrorMessage,
  IconEnum,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams } from "react-router";
import { NotFoundPage } from "src/components/not-found/NotFoundPage";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import graphic from "../common/empty-state-dashboard-graphic.svg";
import { InsightsDateBadge } from "../common/InsightsDatePicker";
import { InsightsPanel } from "../common/InsightsPanel";
import { dateRangeToFormState } from "../common/marshall";
import { InsightsShowDashboardProvider } from "./InsightsShowDashboardContext";

export const InsightsCustomDashboardRoute = () => {
  const { identity } = useIdentity();
  const insightsFeatureGate = identity?.feature_gates.advanced_insights;

  const { insightsV3 } = useFlags();

  const { id } = useParams<{ id: string }>() as { id: string };

  const {
    data: dashboardData,
    error: dashboardError,
    isLoading: dashboardLoading,
  } = useAPI("insightsShowCustomDashboard", {
    id,
  });

  if (!insightsV3) {
    return <NotFoundPage />;
  }

  if (!insightsFeatureGate) {
    return <OrgAwareNavigate to="/insights-v3" />;
  }

  const error = dashboardError;

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const loading = dashboardLoading;
  if (loading || !dashboardData) {
    return <FullPageLoader />;
  }

  return <InsightsCustomDashboardPage dashboard={dashboardData.dashboard} />;
};

const InsightsCustomDashboardPage = ({
  dashboard,
}: {
  dashboard: CustomDashboard;
}) => {
  const isEmptyDashboard = dashboard.panels.length === 0;

  return (
    <InsightsShowDashboardProvider dashboard={dashboard} filterFields={[]}>
      <PageWrapper
        width={PageWidth.Wide}
        icon={dashboard.icon}
        color={dashboard.color as unknown as ColorPaletteEnum}
        title={dashboard.name}
        className="bg-surface-secondary"
        crumbs={[
          {
            title: "Insights",
            to: "/insights-v3",
          },
        ]}
        backHref="/insights-v3"
        noPadding
        accessory={
          // TODO: filters
          <div className="flex items-center gap-2">
            <InsightsDateBadge
              dateRange={dateRangeToFormState(dashboard.date_range)}
              size={BadgeSize.Large}
            />
            {/* Only display Edit dashboard button if the dashboard has 1 or more panels */}
            {!isEmptyDashboard && (
              <GatedButton
                requiredScope={ScopeNameEnum.InsightsCustomDashboardsUpdate}
                theme={ButtonTheme.Primary}
                icon={IconEnum.Edit}
                analyticsTrackingId="insights-edit-dashboard"
                href={`/insights-v3/dashboards/custom/${dashboard.id}/edit`}
              >
                Edit dashboard
              </GatedButton>
            )}
          </div>
        }
      >
        {isEmptyDashboard ? (
          <InsightsCustomDashboardEditEmptyState />
        ) : (
          <div className="flex flex-col grow gap-6 p-6">
            {dashboard.panels.map((panel, idx) => (
              <InsightsPanel
                key={idx}
                panel={panel}
                panelIdx={idx}
                editable={false}
              />
            ))}
          </div>
        )}
      </PageWrapper>
    </InsightsShowDashboardProvider>
  );
};

const InsightsCustomDashboardEditEmptyState = () => {
  return (
    <div className="flex flex-col grow p-6 bg-white">
      <FullPageEmptyState
        graphic={<img src={graphic} className="my-6" />}
        title={"Add charts and text to your new dashboard"}
        subtitle={
          "Generate post-mortems, schedule debriefs and capture follow-ups to consistently learn from incidents."
        }
        gradientClasses={"from-[#F7F7F8] to-[#FFFFFF]"}
        cards={<></>}
        cta={
          <Button
            onClick={() => void 0} // TODO [FND-1259]: Open Add panels drawer
            analyticsTrackingId={null}
            icon={IconEnum.Chart}
            theme={ButtonTheme.Primary}
          >
            Add charts
          </Button>
        }
      />
    </div>
  );
};
