import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Markdown } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import { ActivityLogEntry, Actor, Incident } from "src/contexts/ClientContext";

export const ActivityItemSlackInferSentry = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.slack_infer_sentry) {
    throw new Error(
      "malformed timeline item: slack_infer_sentry was missing slack_infer_sentry field",
    );
  }

  const actor: Actor = {
    user: item.content.slack_infer_sentry.user,
  };

  return {
    actor: actor,
    icon: IconEnum.Github,
    colour: ColorPaletteEnum.Purple,
    title: "GitHub link mentioned",
    quotedContent: (
      <Markdown>{item.content.slack_infer_sentry.slack_message.text}</Markdown>
    ),
  };
};
